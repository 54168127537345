<template>
  <div class="right-content"><div class="title">退货政策</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-11-11 20:17:39
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><div class="clearfix">&nbsp;</div>
    <div class="container">
      <div class="page-container no-border">
        <div>
          <ol>
            <li>1. 商品在运输过程中造成的破损，客户收货时一定要当面签收，遇到爆罐、漏粉（外包装破损，奶粉有外撒现象）的情况，
              第一时间联系{{ $tp().OPEN_COM_NAME }}客服人员，经过客服人员给出（拒签或签收）处理办法。 如果在快递员离开之后再来反馈有爆罐漏粉现象，我司不予处理。</li>
            <li>2. 商品在运输过程中丢失，经{{ $tp().OPEN_COM_NAME }}客服核查情况属实可申请退款。</li>
            <li>3. 收到商品与实际购买商品不符，经核查属实可申请退款退货。</li>
            <li>4. 如果收到的商品已过保质期，经核查属实可申请退款退货。</li>
            <li>5. 自发货日起，用户超过30天未收到商品，经核查属实可申请退款退货。</li>
          </ol>
        </div>

        <div>
          <ol>
            <li>任何非{{ $tp().OPEN_COM_NAME }}出售的商品，将不支持退款退货。</li>
            <li>商品已使用并影响二次销售，将不支持退款退货。</li>
            <li>超过受理时限（签收后24小时内未提交申请将不能申请售后）</li>
            <li>由于运输过程中的不可抗拒因素，商品出现轻微破损，但不影响正常使用</li>
            <li>任何因客户的原因非正常使用和保管。导致出现商品质量问题的，将不支持退款退货。</li>
            <li>礼包或套装中的商品不可以退换货</li>
          </ol>
        </div>

        <div>
          <ol>
            <li>任何非{{ $tp().OPEN_COM_NAME }}出售的商品，将不支持退款退货。</li>
            <li>商品已使用并影响二次销售，将不支持退款退货。</li>
            <li>超过受理时限（签收后24小时内未提交申请将不能申请售后）</li>
            <li>由于运输过程中的不可抗拒因素，商品出现轻微破损，但不影响正常使用</li>
            <li>任何因客户的原因非正常使用和保管。导致出现商品质量问题的，将不支持退款退货。</li>
            <li>礼包或套装中的商品不可以退换货</li>
            <li>因客户原因导致退货的物流快递费由客户自行承担，因物流及商家原因导致退货的，物流快递费由商家承担，请您退货前一定要和售后客服联系</li>

          </ol>
        </div>
      </div>
    </div></div></div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:41
 * @version 1.0
 * @description
 */
export default {
  name: 'ReturnsPolicy'
}
</script>

<style scoped>

</style>
